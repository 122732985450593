import defu from 'defu'
import type { UseSeoMetaInput } from '@unhead/vue'

import {
  DEFAULT_TITLE,
  OG_DEFAULT_IMAGE,
  OG_THUMBNAIL,
  RETAIN_FILTER_QUERY_IN_CANONICAL_URLS,
} from 'configuration/global.configuration'
import { capitalize, capitalizeSentence } from 'lib/strings'
import type { Product } from 'types/models/product'
import type { StoryCategory, StoryParameter } from 'types/storyblok'
import type { MetaSEO, PageMetaStory, PrimaryMeta } from 'types/seo'
import type { Market } from 'types/models/market'
import type { Route } from 'types/route'
import { parseQuery, parseURL, withQuery, withTrailingSlash, withoutTrailingSlash } from 'ufo'
import { extractPageFromRoute, isHomePagePath } from './routing'
import { getFilterItemTranslationKeyByQuery } from './filters'
import type { LandingPageStory } from '~/types/storyblok/pages/landing-page'
import type { WarehouseRegion } from '~/types/models/storefront'

const DEFAULT_DESCRIPTION_STORYBLOK_KEY = 'defaultPageDescription'

export function getSEODataFromStoryCategory(category: StoryCategory): MetaSEO {
  const storyblokStore = useStoryblokStore()

  return {
    title: category.content.meta_title || capitalizeSentence(category.name) || '',
    description: category.content.meta_description || storyblokStore.getTranslation(DEFAULT_DESCRIPTION_STORYBLOK_KEY),
    img: category.content.hero?.[0]?.image?.filename || storyblokStore.getConstant(OG_THUMBNAIL)?.value || OG_DEFAULT_IMAGE,
    meta_robots: category.content.meta_robots,
  }
}
export function getSEODataFromStoryPage(page?: LandingPageStory): MetaSEO & { meta_robots: string[] } {
  const storyblokStore = useStoryblokStore()

  return {
    title: page?.content.seo.title || '',
    description: page?.content.seo.description || storyblokStore.getTranslation(DEFAULT_DESCRIPTION_STORYBLOK_KEY),
    img: page?.content.seo.og_image || storyblokStore.getConstant(OG_THUMBNAIL)?.value || OG_DEFAULT_IMAGE,
    meta_robots: page?.content.meta_robots || [],
  }
}

export function getSEODataFromProduct(product: Product): MetaSEO {
  const { $t } = useNuxtApp()

  return {
    title: capitalizeSentence(product.meta.title || '') || capitalizeSentence(product.name),
    description:
      product.meta.description
      || product.description.short
      || product.description.full
      || $t(DEFAULT_DESCRIPTION_STORYBLOK_KEY),
    img:
      product?.media?.hero?.original?.url
      || product?.media?.hero?.small?.url
      || product?.media?.hero?.thumbnail?.url
      || (product?.media?.images[0] && product.media.images[0].url)
      || undefined,
  }
}

/**
 * @deprecated Use `getSEODataFromStoryCategory` or getSEODataFromStoryPage instead
 */
export function createStory(params: StoryParameter): PageMetaStory {
  const { $t } = useNuxtApp()
  const { title, description, image, metaRobots, path } = params

  return {
    content: {
      SEO: {
        title: capitalizeSentence(title || '') || 'Happy Socks',
        description: description || $t(DEFAULT_DESCRIPTION_STORYBLOK_KEY),
        img: image || undefined,
      },
      meta_robots: metaRobots || [],
    },
    full_slug: path || '',
  }
}

export function getBaseUrl(options?: {
  baseUrlClient: string
}) {
  const computedBaseUrl = options?.baseUrlClient || useRuntimeConfig().public.baseUrlClient
  const baseUrl = computedBaseUrl === '/' ? 'https://www.happysocks.com' : computedBaseUrl
  return baseUrl
}

export function generateAlternateLinks(options?: {
  fullPath: string
  baseUrlClient: string
  allMarkets: Market[]
  excludeWarehouse?: WarehouseRegion[]
}) {
  const computedBaseUrl = options?.baseUrlClient || useRuntimeConfig().public.baseUrlClient
  const baseUrl = computedBaseUrl === '/' ? 'https://www.happysocks.com' : computedBaseUrl
  const markets = options?.allMarkets || useStorefrontStore().allMarkets
  const path = options?.fullPath || useRoute().fullPath

  // NOTE: We remove all params from the URL to avoid unnecessary additional canonical links
  const formattedPath = path ? path.split('?')[0] : undefined

  return markets.map((market) => {
    const { locale, storefrontCode } = market

    if (options?.excludeWarehouse?.includes(market.warehouse.region))
      return {}

    const href = formattedPath
      ? `${baseUrl}/${storefrontCode}/${formattedPath.slice(4)}`
      : `${baseUrl}/${storefrontCode}`

    return {
      hid: `alternate:${locale.html.toLowerCase()}`,
      rel: 'alternate',
      hrefLang: locale.html.toLowerCase(),
      // ufo:withoutTrailingSlash seems not working
      href: href.endsWith('/') ? href.substring(0, href.length - 1) : href,
    }
  })
}

type CanonicalRoute = Route

/**
 * Determines whether queries should be retained based on the given `fullPath`.
 * Queries are retained if the `path` includes any of the URLs defined in `RETAIN_FILTER_QUERY_IN_CANONICAL_URLS`
 * and all the query parameters specified in the URL are present in the `query` object.
 * Additionally, the values of the query parameters must be of type 'string' and should not contain a comma.
 *
 * @param fullPath - The full path to be checked for query retention.
 * @returns A boolean indicating whether queries should be retained.
 */
function shouldRetainQueries(fullPath: string) {
  const { pathname: path, search } = parseURL(fullPath)
  const query = parseQuery(search)

  let shouldRetainQueries = false

  for (const [url, queries] of RETAIN_FILTER_QUERY_IN_CANONICAL_URLS) {
    if (path.includes(url)) {
      shouldRetainQueries = Object.entries(query).every(([key, value]) => {
        const isQueryIncluded = queries.includes(key)
        const isValueString = typeof value === 'string'
        const isValueValid = isValueString && !value.includes(',')
        return isQueryIncluded && isValueString && isValueValid
      })
      break
    }
  }

  return shouldRetainQueries
}

function getOrderOfQueries(fullPath: string) {
  return RETAIN_FILTER_QUERY_IN_CANONICAL_URLS.find(([url]) =>
    fullPath.includes(url),
  )?.[1]
}

function createQueryTitlePrefix(fullPath: string) {
  if (!shouldRetainQueries(fullPath))
    return ''

  const orderOfQueries = getOrderOfQueries(fullPath)

  if (!orderOfQueries)
    return ''

  const { search } = parseURL(fullPath)
  const query = parseQuery(search)

  const queryTitlePrefix = orderOfQueries
    .reduce((titlePrefix, queryKey) => {
      const queryValue = query[queryKey]
      if (!queryValue || typeof queryValue !== 'string')
        return titlePrefix

      const translationKey = getFilterItemTranslationKeyByQuery(queryKey, queryValue)
      const translatedValue = useStoryblokStore().getTranslation(translationKey)

      return `${titlePrefix} ${capitalize(translatedValue)}`
    }, '')

  return queryTitlePrefix ? `${queryTitlePrefix} ` : ''
}

function retainFilterQueriesInCanonical(fullPath: string) {
  const { pathname: path, search } = parseURL(fullPath)
  const query = parseQuery(search)

  if (!shouldRetainQueries(fullPath))
    return path

  const orderOfQueries = getOrderOfQueries(fullPath)

  interface Query { [key: string]: string }
  const orderedQuery = orderOfQueries
    ? orderOfQueries
      .reduce((obj: Query, queryKey) => {
        const queryValue = query[queryKey]
        if (!queryValue || typeof queryValue !== 'string')
          return obj

        obj[queryKey] = queryValue
        return obj
      }, {})
    : {}

  return withQuery(path, orderedQuery)
}

export function generateCanonicalLink(options: { route: CanonicalRoute, baseUrlClient?: string }) {
  const route = options.route

  if (!route)
    return null

  const nonCanonicalSlugParts = ['cart', 'address', 'payment', 'success']
  const noneCanonicalSlugs = nonCanonicalSlugParts.map(slugPart => `/checkout/${slugPart}`)
  const computedBaseUrl = options?.baseUrlClient || useRuntimeConfig().public.baseUrlClient
  const baseUrl = computedBaseUrl === '/' ? 'https://www.happysocks.com' : computedBaseUrl

  const {
    params: { storefront, sku },
    path,
    query,
  } = route

  if (sku) {
    return {
      rel: 'canonical',
      href: `${baseUrl}/${storefront}/product/${sku}`,
    }
  }

  const { pathWithoutPage } = extractPageFromRoute(route)
  const fullPath = withQuery(pathWithoutPage, query)

  let finalPath = ''
  let fullHref = ''

  if (noneCanonicalSlugs.some(slug => path.includes(slug)))
    finalPath = storefront ? `/${storefront}` : '/'
  else
    finalPath = retainFilterQueriesInCanonical(fullPath)

  if (isHomePagePath(finalPath, storefront as string))
    fullHref = withTrailingSlash(`${baseUrl}${finalPath}`)
  else
    fullHref = withoutTrailingSlash(`${baseUrl}${finalPath}`)

  return {
    rel: 'canonical',
    href: fullHref,
  }
}

export function generateSeoLinks(route: Route) {
  return [
    ...generateAlternateLinks(),
    ...[generateCanonicalLink({ route }) ?? {}],
    {
      rel: 'icon',
      type: 'image/png',
      href: '/favicon.png',
    },
  ]
}

export function getSeoTitle(
  SEO: Pick<MetaSEO, 'title'> | null,
  storeCode: string,
  options?: { route?: Pick<Route, 'fullPath'> },
): string {
  const { route } = defu(options, { route: undefined })

  let title = ''
  const filterPrefix = route ? createQueryTitlePrefix(route.fullPath) : ''
  title += filterPrefix

  if (!SEO?.title)
    return `${title}${DEFAULT_TITLE} ${storeCode.toUpperCase()}`

  if (/(\| Happy [Ss]ocks)$/.test(SEO.title))
    return `${title}${SEO.title} ${storeCode.toUpperCase()}`

  if (/(\| Happy [Ss]ocks) \[?[A-Za-z]{2}\]?$/.test(SEO.title))
    return title + SEO.title

  return `${title}${SEO.title} | ${DEFAULT_TITLE} ${storeCode.toUpperCase()}`
}

export function getSeoHeadData(
  SEO: MetaSEO | null,
  storeCode: string,
  options?: { route: Pick<Route, 'fullPath'> },
): UseSeoMetaInput {
  const title = getSeoTitle(SEO, storeCode, options)
  const storyblokStore = useStoryblokStore()
  const defaultOgImage = storyblokStore.getConstant(OG_THUMBNAIL)?.value || OG_DEFAULT_IMAGE
  return {
    title,
    description: SEO?.description || '',
    ogDescription: SEO?.description || '',
    ogTitle: title,
    ogImage: SEO?.img ? SEO.img : defaultOgImage,
    ogUrl: options?.route?.fullPath || '',
    twitterTitle: title,
    twitterDescription: SEO?.description || '',
    twitterImage: SEO?.img ? SEO.img : defaultOgImage,
    twitterCard: 'summary',
  }
}

export function getPrimaryMeta(
  SEOTitle: string,
  description: string,
  meta_robots?: string[],
): {
    name: string
    content: string
  }[] {
  const meta: PrimaryMeta[] = [
    { name: 'title', content: SEOTitle },
    { name: 'description', content: description },
  ]

  if (meta_robots && meta_robots.length)
    meta.push({ name: 'robots', content: meta_robots.join(',') })

  return meta
}
